import styled from 'styled-components'
import bg1 from '../../assets/images/bg1.jpg'

export const SectionPricipal = styled.section` 

    @import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap');

    background-color: #fff;

    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    height: 100vh;
    display:flex;
    flex-direction:column;
    align-items:center;
    

`

export const Header = styled.header` 

    height: 90px;
    width: 100%;
    position: fixed;
    z-index:99;
    background-color: #fff;
    display: flex;
    align-items:center;
    justify-content: center;

    .header-inner{
        height: 90px;
        width: 100%;
        max-width: 1200px;
        background-color: #fff;
        display: flex;
        align-items:center;
        position: fixed;
        
        .header-inner1{
            width: calc(100% - 300px);
            height:100%;
            display: flex;
            align-items:center;
            .logo-top{
                height: 50px;
            }
        }

        .header-inner2{
            width: 300px;
            height:100%;
            background-color: #25DC8C;
            display: flex;
            align-items:center;

        }

    }

    .header-inner3{
            width: 100%;
            height:100%;
            background-color: #fff;
            float:right;

            .header-inner4{
            width: 50%;
            height:100%;
            background-color: #25DC8C;
            float:right;
    }
    }

`

export const Menu = styled.nav`

` 

export const Banner = styled.section` 

width:100%;
height:100%;
display: flex;
align-items:center;
justify-content: center;
margin-top:90px;


.banner-inner{
    width:100%;
    height:100%;
    max-width: 1200px;
    display: flex;
    


    .banner-inner1{


        width:50%;
        height:100%;
        

    }

    .banner-inner2{

        width:50%;
        height:100%;
        z-index:10;
        
        

        .banner-inner3{

            background-color: #25DC8C;
            width: 300px;
            height:100%;
            float: right;
        }

        .banner-fotos{
            height:calc(100% - 90px);
            position: absolute;
            float: right;




            .banner-fotos-inner{
                height:calc(100% - 90px);
                min-height: 420px;
                background-color: #eee;
                background-position: center; 
                background-repeat: no-repeat; 
                background-size: cover; 
                background-image: url(${bg1});
                width: 44vw;
                border-radius: 1%;

                

            }
        }

    }
}


.banner-inner4{

        width: 100%;
        height:calc(100% - 90px);
        position: absolute;
        float: right;
        display: flex;
        justify-content: flex-end;
        z-index:0;

        .banner-inner5{
            background-color: #25DC8C;
            width: 25%;
            height:100%;
            position: absolute;
            float: right;
            z-index:0;

    }

    }



`