import React, { Component } from 'react'
import { SectionPricipal, Header, Banner, Menu } from './style'
import logo from '../../assets/images/logo_nufit.png'
class Home extends Component {

    render() {

        return (
            <>
                <SectionPricipal>

                    <Header>

                        <div className="header-inner">

                            <div className="header-inner1">
                                <img className="logo-top" src={logo} alt='logo' />

                                <Menu>
                                    
                                </Menu>

                            </div>

                            <div className="header-inner2">

                            </div>

                        </div>

                        <div className="header-inner3">

                            <div className="header-inner4">

                            </div>

                        </div>


                    </Header>

                    <Banner>

                        <div className="banner-inner">


                            <div className="banner-inner1">


                            </div>

                            <div className="banner-inner2">

                                <div className="banner-fotos"> 
                                    <div className="banner-fotos-inner"> 
                                    
                                    </div>                                
                                </div>

                                <div className="banner-inner3"> </div>


                            </div>


                        </div>

                        <div className="banner-inner4">
                            <div className="banner-inner5">

                            </div>
                        </div>




                    </Banner>

                </SectionPricipal>



            </>

        )


    }

}

export default Home